// @ts-nocheck
import React from 'react';

import ChildCareImg from '../../assets/serviceicons/child_care.png';
import ClothingHaircutsImg from '../../assets/serviceicons/clothing_haircuts.png';
import EducationTrainingImg from '../../assets/serviceicons/education_training.png';
import FinancialCreditCounselingImg from '../../assets/serviceicons/financial_credit_counseling.png';
import FoodMealsImg from '../../assets/serviceicons/food_meals.png';
import HomelessPreventionImg from '../../assets/serviceicons/homeless_prevention.png';
import HousingAssistanceImg from '../../assets/serviceicons/housing_assistance.png';
import JobPlacementImg from '../../assets/serviceicons/job_placement.png';
import LegalAssistanceImg from '../../assets/serviceicons/legal_assistance.png';
import MedicalAssistanceImg from '../../assets/serviceicons/medical_assistance.png';
import MentalHealthImg from '../../assets/serviceicons/mental_health_crisis_support.png';
import PeerSupportImg from '../../assets/serviceicons/peer_support.png';
import RestroomShowersImg from '../../assets/serviceicons/restrooms_showers.png';
import ShelterImg from '../../assets/serviceicons/shelter.png';
import TransportationImg from '../../assets/serviceicons/transportation.png';

const SERVICE_ICONS = {
    //'Case Management':''
    'Child Care': ChildCareImg,
    'Clothing / Haircuts': ClothingHaircutsImg,
    'Education / Training': EducationTrainingImg,
    //'Emergency/Safety':''
    'Financial / Credit Counseling': FinancialCreditCounselingImg,
    'Food / Meals': FoodMealsImg,
    'Homeless Prevention': HomelessPreventionImg,
    'Housing Assistance': HousingAssistanceImg,
    'Job Placement': JobPlacementImg,
    'Legal Assistance': LegalAssistanceImg,
    'Medical Assistance': MedicalAssistanceImg,
    'Mental Health / Crisis Support': MentalHealthImg,
    'Peer Support': PeerSupportImg,
    'Restrooms / Showers': RestroomShowersImg,
    'Shelter': ShelterImg,
    'Transportation': TransportationImg
};

/**
 * Displays a visual icon representation of a specific service (i.e. shelter)
 * @param {*} props 
 * @author BrianCastor
 */
 export const ServiceIcon = (props) => {
    if (!props.service) {
        return null;
    }

    /*Service Categories (food, shelter, etc) - only show ones marked as True*/
    let icon = (Object.keys(SERVICE_ICONS).includes(props.service) ? SERVICE_ICONS[props.service] : SERVICE_ICONS['Peer Support'])

    return (
        <img src={icon} alt={props.service} style={{ width: '30px', height: '30px' }} />
    )
 }